import React, { useEffect } from "react";
import clsx from "clsx";
import styles from "./style.module.scss";
import icon from "../../assets/image/calendar.png";
import shedule from "../../assets/image/shedule.png";
import sheduleMob from "../../assets/image/clock-mob.png";
import { IconClock } from "../../assets";
import { Clock } from "../clock";

export const Shedule = () => {
  return (
    <div className={styles.shedule}>
      <div className="container">
        <div className={styles.heading}>
          <img
            src={icon}
            alt=""
            className={clsx(styles.heading__icon, "bounceIn wow")}
            data-wow-offset="50"
            data-wow-delay="0.5s"
          />
          <h2
            className="h2 text-center animated fadeInUp wow"
            data-wow-offset="50"
            data-wow-delay="0.5s"
          >
            Schedule example
          </h2>
          <p
            className="f25 text-center animated fadeInUp wow"
            data-wow-offset="50"
            data-wow-delay="0.5s"
          >
            Every hour we raffle prizes in a simple, understandable and fair way.
          </p>
        </div>
      </div>
      <div className="">
        <div className={styles["shedule-image"]}>
          <Clock />
          {/* <img src={shedule} alt="" className={styles["shedule-image--pc"]} />
          <img
            src={sheduleMob}
            alt=""
            className={styles["shedule-image--mob"]}
          /> */}
        </div>
      </div>
      <div className="container">
        <div className={clsx(styles["shedule-bottom"], "scene")}>
          <h4 className={styles["shedule-bottom__text"]}>
            Participate and <span>compete</span> in intuition
          </h4>
          <a
            href="https://justdoluck.com/user/schedule"
            target="_blank"
            className={clsx(styles["button"], "animated fadeInDown wow")}
            data-wow-offset="50"
            data-wow-delay="0.5s"
          >
            <div className={styles["button-inner"]}>
              <div className={styles["button-icon"]}>
                <IconClock />
                <span className={clsx(styles.dot, "dot--green")} />
              </div>{" "}
              Schedule
              <span className={clsx(styles.dot, styles["dot--yellow"])} />
              <span className={clsx(styles.dot, styles["dot--blue"])} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
