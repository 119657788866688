import React, {useCallback, useEffect, useState} from "react";
import {IconDown, IconIn, IconMeta, IconTg, IconTw,} from "../../assets";
import styles from "./style.module.scss";
import clsx from "clsx";
import img1 from "../../assets/image/giftbox.png";
import img2 from "../../assets/image/man.png";
import img3 from "../../assets/image/woman.png";
import img4 from "../../assets/image/curved.png";
import img5 from "../../assets/image/cylinder.png";
import img6 from "../../assets/image/saly.png";
import {Link} from "react-scroll";
import Timer from "../timer";
import EnvController from "../../utils/EnvController";

export const Banner = () => {
  const [nearestId, setNearestId] = useState(0);
  const [countDownDate, setCountDownDate] = useState(new Date());

  useEffect(() => {
    getTime();
  }, []);

  const getTime = useCallback(async () => {
    try {
      const response = await fetch(
          `${EnvController.getEnvController().wayToApi}game/public-scope/games/nearest-one`, {
            headers: {
              "Api-Key": "justdoluck_api"
            }
          }
      );
      let json = await response.json();
      let time = json.data.startDate;
      setCountDownDate(time);
      setNearestId(json.data.gameId);
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className={styles.banner} id="banner">
      <div>
        <span className={clsx(styles.image, styles.image1, "prl")}>
          <img src={img1} alt="" data-depth=".3" />
        </span>
        <span className={clsx(styles.image, styles.image2, "prl")}>
          <img src={img2} alt="" data-depth=".3" />
        </span>
        <span className={clsx(styles.image, styles.image3, "prl")}>
          <img src={img3} alt="" data-depth=".3" />
        </span>
        <span className={clsx(styles.image, styles.image4, "prl")}>
          <img src={img4} alt="" data-depth=".8" />
        </span>
        <span className={clsx(styles.image, styles.image5, "prl")}>
          <img src={img5} alt="" data-depth=".3" />
        </span>
        <span className={clsx(styles.image, styles.image6, "prl")}>
          <img src={img6} alt="" data-depth=".5" />
        </span>
      </div>
      <div className="container">
        <div className={styles["banner-inner"]}>
          <div
            className={clsx(styles["banner-half"], "animated fadeInLeft wow")}
            data-wow-offset="50"
            data-wow-delay="0.5s"
          >
            <h1 className={styles.title}>Boosting your chances</h1>
            <a href="https://justdoluck.com" className={styles.subtitle} target={"_blank"}>
              with free online giveaways
            </a>
            <a
              href={`https://justdoluck.com/user/raffle?id=${nearestId}`}
              target="_blank"
              className={styles.timer}
            >
              <div className={styles["timer-inner"]}>
                <span className={styles["timer__text"]}>
                  Simple and fun to play!
                </span>
                <span className={styles["timer__value"]}>
                  <Timer data={new Date(countDownDate)} over={getTime} />
                </span>
              </div>
            </a>
          </div>
          <div className={styles.social}>
            <a
              href="https://t.me/justdoluck"
              className={clsx(styles["social-item"], "animated wow fadeInUp")}
              data-wow-offset="50"
              data-wow-delay="0.5s"
            >
              <IconTg />
            </a>
            <a
              href="https://twitter.com/justdoluck"
              className={clsx(styles["social-item"], "animated wow fadeInUp")}
              data-wow-offset="50"
              data-wow-delay="0.6s"
            >
              <IconTw />
            </a>
            <a
              href="https://www.instagram.com/justdoluckworld/"
              className={clsx(styles["social-item"], "animated wow fadeInUp")}
              data-wow-offset="50"
              data-wow-delay="0.7s"
            >
              <IconIn />
            </a>
            <a
              href="https://www.facebook.com/justdoluck"
              className={clsx(styles["social-item"], "animated wow fadeInUp")}
              data-wow-offset="50"
              data-wow-delay="0.8s"
            >
              <IconMeta width={24} height={24} />
            </a>
          </div>
          <Link
            to="services"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className={clsx(styles.down, "animated wow fadeInUp")}
            data-wow-offset="50"
            data-wow-delay="0.8s"
          >
            <IconDown className={styles.down} />
          </Link>
        </div>
      </div>
    </div>
  );
};
