import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import "./style.scss";
import clock from "../../assets/image/clock/clock2.png";
import seconds from "../../assets/image/clock/clock-seconds.png";
import minutes from "../../assets/image/clock/clock-minutes.png";
import hours from "../../assets/image/clock/clock-hours.png";
import point from "../../assets/image/clock/clock-point.png";
import clockMob from "../../assets/image/clock/1clock-mob.png";
import clockMob2 from "../../assets/image/clock/2clock-mob.png";
import clockMob3 from "../../assets/image/clock/3clock-mob.png";

export const Clock = () => {
  const clock_sec_1 = useRef<HTMLImageElement>(null);
  const clock_sec_2 = useRef<HTMLImageElement>(null);
  const clock_sec_3 = useRef<HTMLImageElement>(null);
  const clock_sec_4 = useRef<HTMLImageElement>(null);
  const clock_min_1 = useRef<HTMLImageElement>(null);
  const clock_min_2 = useRef<HTMLImageElement>(null);
  const clock_min_3 = useRef<HTMLImageElement>(null);
  const clock_min_4 = useRef<HTMLImageElement>(null);
  const clock_hour_1 = useRef<HTMLImageElement>(null);
  const clock_hour_2 = useRef<HTMLImageElement>(null);
  const clock_hour_3 = useRef<HTMLImageElement>(null);
  const clock_hour_4 = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let getSecondsAngleFromCurrentTime = () => {
      let seconds = new Date().getSeconds();
      let angle = seconds * 6 - 2;
      return angle;
    };
    let getMinutesAngleFromCurrentTime = () => {
      let currentTime = new Date();
      let minutes = currentTime.getMinutes() + currentTime.getSeconds() / 60;
      let angle = minutes * 6;
      return angle;
    };
    let getHoursAngleFromCurrentTime = () => {
      let currentTime = new Date();
      let hours =
        (currentTime.getHours() % 12) +
        currentTime.getMinutes() / 60 +
        currentTime.getSeconds() / 3600;
      let angle = (360 / 12) * hours;
      return angle;
    };

    let setSecondArrowsAngle = () => {
      if (clock_sec_1.current) {
        clock_sec_1.current.style.transform =
          "rotate(" + getSecondsAngleFromCurrentTime() + "deg)";
      }
      if (clock_sec_2.current) {
        clock_sec_2.current.style.transform =
          "rotate(" + getSecondsAngleFromCurrentTime() + "deg)";
      }
      if (clock_sec_3.current) {
        clock_sec_3.current.style.transform =
          "rotate(" + getSecondsAngleFromCurrentTime() + "deg)";
      }
      if (clock_sec_4.current) {
        clock_sec_4.current.style.transform =
          "rotate(" + getSecondsAngleFromCurrentTime() + "deg)";
      }
    };
    let setMinuteArrowsAngle = () => {
      if (clock_min_1.current) {
        clock_min_1.current.style.transform =
          "rotate(" + getMinutesAngleFromCurrentTime() + "deg)";
      }
      if (clock_min_2.current) {
        clock_min_2.current.style.transform =
          "rotate(" + getMinutesAngleFromCurrentTime() + "deg)";
      }
      if (clock_min_3.current) {
        clock_min_3.current.style.transform =
          "rotate(" + getMinutesAngleFromCurrentTime() + "deg)";
      }
      if (clock_min_4.current) {
        clock_min_4.current.style.transform =
          "rotate(" + getMinutesAngleFromCurrentTime() + "deg)";
      }
    };
    let setHourArrowsAngle = () => {
      if (clock_hour_1.current) {
        clock_hour_1.current.style.transform =
          "rotate(" + getHoursAngleFromCurrentTime() + "deg)";
      }
      if (clock_hour_2.current) {
        clock_hour_2.current.style.transform =
          "rotate(" + getHoursAngleFromCurrentTime() + "deg)";
      }
      if (clock_hour_3.current) {
        clock_hour_3.current.style.transform =
          "rotate(" + getHoursAngleFromCurrentTime() + "deg)";
      }
      if (clock_hour_4.current) {
        clock_hour_4.current.style.transform =
          "rotate(" + getHoursAngleFromCurrentTime() + "deg)";
      }
    };
    let interval: any;
    let makeClockWork = () => {
      interval = setInterval(() => {
        setSecondArrowsAngle();
        setMinuteArrowsAngle();
        setHourArrowsAngle();
      }, 1000);
    };

    makeClockWork();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="clockbg" style={{ maxWidth: 1280 }}>
      <img
        src={clock}
        className=" image1 animated fadeIn wow d-none d-sm-block"
        data-wow-offset="50"
        data-wow-delay="0.5s"
        alt=""
      />
      <img
        src={seconds}
        id="clock_sec_1"
        ref={clock_sec_1}
        style={{ transform: "rotate(145deg)" }}
        className="clock-seconds image-edges animated fadeIn wow d-none d-sm-block"
        alt=""
      />
      <img
        src={minutes}
        id="clock_min_1"
        ref={clock_min_1}
        style={{ transform: "rotate(15deg)" }}
        className="clock-minutes image-edges animated fadeIn wow d-none d-sm-block"
        alt=""
      />
      <img
        src={hours}
        id="clock_hour_1"
        ref={clock_hour_1}
        style={{ transform: "rotate(90deg)" }}
        className="clock-hours image-edges animated fadeIn wow d-none d-sm-block"
        alt=""
      />
      <img
        src={point}
        className="clock-point image-edges animated fadeIn wow d-none d-sm-block"
        alt=""
      />

      <div className="mob-clock d-block d-sm-none">
        <div>
          <img src={clockMob} className="mobimage1 image-edges" alt="" />

          <div className="rightclockmob">
            <img
              src={seconds}
              id="clock_sec_2"
              ref={clock_sec_2}
              style={{ transform: "rotate(240deg)" }}
              className="clock-seconds-mob image-edges"
              alt=""
            />
            <img
              src={minutes}
              id="clock_min_2"
              ref={clock_min_2}
              style={{ transform: "rotate(320deg)" }}
              className="clock-minutes-mob image-edges"
              alt=""
            />
            <img
              src={hours}
              id="clock_hour_2"
              ref={clock_hour_2}
              style={{ transform: "rotate(20deg)" }}
              className="clock-hours-mob image-edges"
              alt=""
            />
            <img src={point} className="clock-point-mob image-edges" alt="" />
          </div>
        </div>
      </div>

      <div className="mob-clock d-block d-sm-none">
        <div>
          <img src={clockMob2} className="mobimage3 image-edges" alt="" />

          <div className="leftclockmob">
            <img
              src={seconds}
              id="clock_sec_3"
              ref={clock_sec_3}
              style={{ transform: "rotate(240deg)" }}
              className="clock-seconds-mob image-edges"
              alt=""
            />
            <img
              src={minutes}
              id="clock_min_3"
              ref={clock_min_3}
              style={{ transform: "rotate(320deg)" }}
              className="clock-minutes-mob image-edges"
              alt=""
            />
            <img
              src={hours}
              id="clock_hour_3"
              ref={clock_hour_3}
              style={{ transform: "rotate(20deg)" }}
              className="clock-hours-mob image-edges"
              alt=""
            />
            <img src={point} className="clock-point-mob image-edges" alt="" />
          </div>
        </div>
      </div>

      {/* <div className="mob-clock d-block d-sm-none">
        <div>
          <img src={clockMob3} className="mobimage1 image-edges" alt="" />

          <div className="rightclockmoblast">
            <img
              src={seconds}
              id="clock_sec_4"
              ref={clock_sec_4}
              style={{ transform: "rotate(240deg)" }}
              className="clock-seconds-mob image-edges"
              alt=""
            />
            <img
              src={minutes}
              id="clock_min_4"
              ref={clock_min_4}
              style={{ transform: "rotate(320deg)" }}
              className="clock-minutes-mob image-edges"
              alt=""
            />
            <img
              src={hours}
              id="clock_hour_4"
              ref={clock_hour_4}
              style={{ transform: "rotate(20deg)" }}
              className="clock-hours-mob image-edges"
              alt=""
            />
            <img src={point} className="clock-point-mob image-edges" alt="" />
          </div>
        </div>
      </div> */}
    </div>
  );
};
