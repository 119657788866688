import React, { useState } from "react";
import styles from "./style.module.scss";
import spring from "../../assets/image/Helix.png";
import clsx from "clsx";
import youtube from "../../assets/image/youtube.png";
import cursor from "../../assets/image/cursor-copy.png";
import coins from "../../assets/image/coins.png";
import Modal from "../modal";
import YoutubeModal from "../ytb-modal/YoutubeModal";

export const Steps = () => {
  const [open, setOpen] = useState(false);
  const link = "https://www.youtube.com/embed/gJ_BB38zMBA"

  return (
    <div className={styles.steps}>
      <YoutubeModal isOpen={open} setOpen={setOpen} link={link}/>
      <span className={clsx(styles.spring, "prl")}>
        <img src={spring} alt="" data-depth="1.5" />
      </span>
      <div className="container">
        <div className={styles.heading}>
          <div className="h2 text-center">3 steps to new emotions</div>
        </div>
        <div className={styles["steps-list"]}>
          <div
            className={clsx(styles["steps-list__item"], "fadeInLeft wow")}
            data-wow-offset="50"
            data-wow-delay="0.1s"
          >
            <div className={clsx(styles.box, styles.yellow)}>
              <h5 className={styles.box__step}>Step 1</h5>
              <h3 className={styles.box__title}>Look how to play</h3>
              <div className={styles.box__link} onClick={() => setOpen(true)}>
                Watch the video
              </div>
              <img
                src={youtube}
                alt=""
                className={clsx(styles.box__icon, styles.youtube)}
              />
            </div>
          </div>
          <div
            className={clsx(styles["steps-list__item"], "fadeInUp wow")}
            data-wow-offset="50"
            data-wow-delay="0.3s"
            onClick={()=>{window.open("https://justdoluck.com/user/registration", "_blank")}}

          >
            <div className={clsx(styles.box)}>
              <h5 className={styles.box__step}>Step 2</h5>
              <h3 className={clsx(styles.box__title, styles.middle)}>
                Register and choose a raffle
              </h3>
              <span className={styles.box__link} >
                Register
              </span>
              <img
                src={cursor}
                alt=""
                className={clsx(styles.box__icon, styles.cursor)}
              />
            </div>
          </div>
          <div
            className={clsx(styles["steps-list__item"], "fadeInRight wow")}
            data-wow-offset="50"
            data-wow-delay="0.5s"
            onClick={()=>{window.open("https://justdoluck.com/user/schedule", "_blank")}}

          >
            <div className={clsx(styles.box, styles.blue)}>
              <h5 className={styles.box__step}>Step 3</h5>
              <h3 className={styles.box__title}>Take part</h3>
              <span className={styles.box__link}>
                Begin to play
              </span>
              <img
                src={coins}
                alt=""
                className={clsx(styles.box__icon, styles.coins)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
