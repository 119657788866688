import React, { useState } from "react";
import styles from "./style.module.scss";
import img from "../../assets/image/service.png";
import clsx from "clsx";

export const Services = () => {
  return (
    <div id="services">
      <div className="container">
        <div className={styles.heading}>
          <h2 className="h2 text-center animated fadeInUp wow">
            Our online <span>service</span>
          </h2>
          <p className="f25 text-center animated fadeInUp wow">
            It's the heads or tails game we all played as kids, but now it's
            online and on a different scale.
          </p>
        </div>
        <div className={styles["service-inner"]}>
          <div
            className={clsx(styles["service__image"], "fadeInLeft wow")}
            data-wow-offset="50"
            data-wow-delay="0.5s"
          >
            <img src={img} alt="" />
          </div>
          <div
            className={clsx(styles["service-desc"], "fadeInRight wow")}
            data-wow-offset="50"
            data-wow-delay="0.5s"
          >
            <h3>Just do luck </h3>
            <p>
              - is a game designed for a wide range of users, which has simple
              rules and requires no training time or special skills required.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
