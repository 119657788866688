import { article1, article2, article3, article4, article5 } from "../assets";

import article1Image from "../assets/image/article/article1.jpg"
import article2Image from "../assets/image/article/article2.jpg"
import article3Image from "../assets/image/article/article3.jpg"
export type ArticleType = {
  id: number;
  title: string;
  desc: string;
  image: string;
  modal: string;
  link: string
};

export type NewsType = {
  createdAt: "2022-10-20T05:54:56.000Z";
  id: number;
  photo: string;
  shortDescription: string;
  title: string;
  viewsCount: number;
  description?: string;
};

export const articles = [
  {
    id: 1,
    title: `What is the uniqueness of the prize draw platform “JUST DO LUCK”?`,
    desc: "The prize draw platform “JUST DO LUCK” meets all the principles of uniqueness: novelty, originality, individuality, independence, and interest.",
    image: article1Image,
    modal: ``,
    link: "https://justdoluck.medium.com/what-is-the-uniqueness-of-the-gaming-platform-just-do-luck-d8c665a84600"
  },
  {
    id: 2,
    title: `About the startup prize draw platform “JUST DO LUCK”`,
    desc: "JUST DO LUCK (JDL) is an innovative online platform that employs advanced technology to select winners of prize drawings. Its unique features and benefits are detailed in the article.",
    image: article2Image,
    modal: ``,
    link: "https://justdoluck.medium.com/about-the-startup-prize-draw-platform-just-do-luck-8565df212b7c"
  },
  {
    id: 3,
    title: `Short instruction for using the JUST DO LUCK prize draw platform.`,
    desc: "The general scheme for using the “JDL” prize draw platform",
    image: article3Image,
    modal: ``,
    link: "https://justdoluck.medium.com/short-instruction-for-using-the-just-do-luck-prize-draw-platform-b8042147b8c8"
  }
];

export const articles1 = [
  {
    id: 1,
    title: "Human culture was born in the game",
    desc: "Our attention is our most valuable resource. What we pay attention to is multiplied in our lives.",
    image: article1,
  },
  {
    id: 2,
    title: "Play is part of human nature",
    desc: "Our attention is our most valuable resource. What we pay attention to is multiplied in our lives.",
    image: article3,
  },
  {
    id: 3,
    title: "People are always playing",
    desc: "Our attention is our most valuable resource. What we pay attention to is multiplied in our lives.",
    image: article2,
  },
];
