import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import clsx from "clsx";
import icon from "../../assets/image/icon_conf.png";
import enjoi from "../../assets/image/enjoi-mob.png";
import {
  enjoi1,
  enjoi2,
  enjoi3,
  enjoi4,
  enjoi5,
  enjoi6,
  enjoi7,
  enjoi8,
  enjoi9,
  enjoi10,
  enjoi11,
  enjoi12,
  enjoi13,
} from "../../assets";
import { Articles } from "../articles";
import { articles, articles1, NewsType, ArticleType } from "../../mock";
import { Faq } from "../faq";
export const Enjoi = () => {
  return (
    <div className={styles.enjoi}>
      <div className="container">
        <div className={styles.heading}>
          <div className={clsx(styles.heading__icon, "animated zoomIn wow")}>
            <img src={icon} alt="" />
          </div>
          <h2
            className="h2 text-center animated fadeInUp wow"
            data-wow-offset="50"
            data-wow-delay="0.2s"
          >
            <span>Enjoy the spontaneity</span> and the pleasant feeling of your
            own initiative!
          </h2>
          <p
            className="f25 text-center animated fadeInRight wow"
            data-wow-offset="50"
            data-wow-delay="0.5s"
          >
            Enjoy the spontaneity and the pleasant feeling of your own
            initiative!
          </p>
        </div>
        <div className={styles.images}>
          <img src={enjoi} alt="" className={styles.images__mob} />
          <span className={clsx(styles.icon, styles.icon1, "prl")}>
            <img src={enjoi1} alt="" data-depth=".6" />
          </span>
          <span className={clsx(styles.icon, styles.icon2, "prl")}>
            <img src={enjoi2} alt="" data-depth=".8" />
          </span>
          <span className={clsx(styles.icon, styles.icon3, "prl")}>
            <img src={enjoi3} alt="" data-depth=".4" />
          </span>
          <span className={clsx(styles.icon, styles.icon4, "prl")}>
            <img src={enjoi4} alt="" data-depth=".3" />
          </span>
          <span className={clsx(styles.icon, styles.icon5, "prl")}>
            <img src={enjoi5} alt="" data-depth=".6" />
          </span>
          <span className={clsx(styles.icon, styles.icon6, "prl")}>
            <img src={enjoi6} alt="" data-depth=".3" />
          </span>
          <span className={clsx(styles.icon, styles.icon7, "prl")}>
            <img src={enjoi7} alt="" data-depth=".3" />
          </span>
          <span className={clsx(styles.icon, styles.icon8, "prl")}>
            <img src={enjoi8} alt="" data-depth=".7" />
          </span>
          <span className={clsx(styles.icon, styles.icon9, "prl")}>
            <img src={enjoi9} alt="" data-depth=".5" />
          </span>
          <span className={clsx(styles.icon, styles.icon10, "prl")}>
            <img src={enjoi10} alt="" data-depth=".3" />
          </span>
          <span className={clsx(styles.icon, styles.icon11, "prl")}>
            <img src={enjoi11} alt="" data-depth=".5" />
          </span>
          <span className={clsx(styles.icon, styles.icon12, "prl")}>
            <img src={enjoi12} alt="" data-depth=".3" />
          </span>
          <span className={clsx(styles.icon, styles.icon13, "prl")}>
            <img src={enjoi13} alt="" data-depth=".7" />
          </span>
        </div>
      </div>
      <div id="article">
        <Articles articles={articles} title="Articles" />
      </div>

      <Faq />
    </div>
  );
};
