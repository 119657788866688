class EnvController {
    static instance;

    constructor() {}

    //todo - get from .ENV
    wayToApi = `https://api.justdoluck.com/`;
    isDev = window.location.host.includes('dev.');

     static getEnvController = () => {
        if (!EnvController.instance) {
            EnvController.instance = new EnvController();
        }
        return EnvController.instance;
    };
}
export default EnvController;
