import React, { useEffect } from "react";
import { Banner } from "./components/banner";
import { Footer } from "./components/footer/Footer";
import { Header } from "./components/header";
import { Principles } from "./components/principles";
import { Services } from "./components/services";
import { Shedule } from "./components/shedule";
import { Steps } from "./components/steps";
import Parallax from "parallax-js";
import { Three } from "./components/three";
import { Enjoi } from "./components/enjoi";
import { Reasons } from "./components/reasons";
import { Win1 } from "./components/win1";
import WOW from "wowjs";

function App() {
  useEffect(() => {
    new WOW.WOW().init();
    if (window.innerWidth > 767) {
      const text = document.getElementsByClassName("prl");
      Array.from(text).forEach((i) => new Parallax(i));
    }
  }, []);
  return (
    <div className="wrapper">
      <Header />
      <Banner />
      <Services />
      <Steps />
      <Principles />
      <Shedule />
      <Three />
      <Enjoi />
      <Reasons />
      <Footer />
    </div>
  );
}

export default App;
