import React from "react";
import styles from "./style.module.scss";
import clsx from "clsx";
import {
  reason1,
  reason2,
  reason3,
  reason4,
  reason5,
  reason6,
} from "../../assets";

export const Reasons = () => {
  return (
    <div>
      <div className={styles.reason}>
        <div className="container">
          <h2
            className={clsx(styles.title, "animated wow fadeIn")}
            data-wow-offset="50"
            data-wow-delay="0.2s"
          >
            6 reasons <span>join</span>
          </h2>
          <p
            className={clsx(styles.subtitle, "animated wow fadeIn")}
            data-wow-offset="50"
            data-wow-delay="0.4s"
          >
            Not to use just do luck means unreasonably limit your abilities
          </p>
          <div className={styles.list}>
            <div
              className={clsx(styles["list-item"], "animated wow fadeIn")}
              data-wow-offset="50"
              data-wow-delay="0.4s"
            >
              <img src={reason1} alt="" className={styles["list-item__icon"]} />
              <p className={styles["list-item__desc"]}>
                Our raffles give emotional experiences of a fundamentally new
                quality
              </p>
            </div>
            <div
              className={clsx(styles["list-item"], "animated wow fadeIn")}
              data-wow-offset="50"
              data-wow-delay="0.4s"
            >
              <img src={reason2} alt="" className={styles["list-item__icon"]} />
              <p className={styles["list-item__desc"]}>
                A second-takinf action triggers an additional ability
              </p>
            </div>
            <div
              className={clsx(styles["list-item"], "animated wow fadeIn")}
              data-wow-offset="50"
              data-wow-delay="0.4s"
            >
              <img src={reason3} alt="" className={styles["list-item__icon"]} />
              <p className={styles["list-item__desc"]}>
                Use the wisdom: "Do what you can, with what you have, where you
                are"
              </p>
            </div>

            <div
              className={clsx(styles["list-item"], "animated wow fadeIn")}
              data-wow-offset="50"
              data-wow-delay="0.6s"
            >
              <img src={reason4} alt="" className={styles["list-item__icon"]} />
              <p className={styles["list-item__desc"]}>
                Take advantage of the chance, because blindness to chance hides
                the phenomenon of potentiality
              </p>
            </div>
            <div
              className={clsx(styles["list-item"], "animated wow fadeIn")}
              data-wow-offset="50"
              data-wow-delay="0.6s"
            >
              <img src={reason5} alt="" className={styles["list-item__icon"]} />
              <p className={styles["list-item__desc"]}>
                To get in touch with a chaotic reality is pleasant and bold
              </p>
            </div>
            <div
              className={clsx(styles["list-item"], "animated wow fadeIn")}
              data-wow-offset="50"
              data-wow-delay="0.6s"
            >
              <img src={reason6} alt="" className={styles["list-item__icon"]} />
              <p className={styles["list-item__desc"]}>
                The search for opportunities is a deeply rooted human instinct.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.just}>
        <div className={styles["just-heading"]}>
          <p className="fadeInRight animated wow" data-wow-offset="50">
            We know that all people are unique and at the same time united in
            their unconscious desire to control the surrounding reality.
          </p>
          <h2
            className="h2 text-center fadeInRight animated wow"
            data-wow-offset="50"
          >
            The <span>Just Do Luck</span> project is an attempt to implement
            this in a universal game way
          </h2>
        </div>
      </div>
    </div>
  );
};
