import React, {FC, useEffect, useState} from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import {ArticleType} from "../../mock";
import Modal from "../modal";
import {IconIn, IconMeta, IconTg, IconTw} from "../../assets";
import {ArticleModal} from "./modals/ArticleModal";

type Props = {
  articles: ArticleType[];
  title: string;
};

let getQueryParams = () => {
  let search = window.location.search;
  let params = {};
  let key_value_array = search.substring(1).split('&');
  key_value_array.map((value, index, array) => {
    let key = value.split('=')[0];
    let val = value.split('=')[1];
    // @ts-ignore
    params[key] = val;
  });
  return params;
};

export const Articles: FC<Props> = ({articles, title}: Props) => {
  const [open, setOpen] = useState("");
  const [openModal, setOpenModal] = useState({open: false, id: 0});

  useEffect(() => {
    // @ts-ignore
    const id = +getQueryParams().articleId;
    if (!id) {
      return
    }
    setOpenModal({open: true, id})

  }, [])
  return (
      <div className={styles.article}>
        <ArticleModal
            open={openModal.open}
            onClose={() => setOpenModal({open: false, id: openModal.id})}
            id={openModal.id}
        />
        <Modal open={!!open} onClose={() => setOpen("")}>
          <div className={styles.modal}>
            <div dangerouslySetInnerHTML={{__html: open}}/>
        </div>
      </Modal>
      <div className="container">
        <div
          className={clsx(styles.heading, "animated wow fadeInLeft")}
          data-wow-offset="50"
          data-wow-delay="0.2s"
        >
          <div>
            <h2 className="h2">
              {title}
              <span>Just do luck</span>
            </h2>
            <p className="f25">
              Follow us on social networks to be the first to know about new
              raffles and the best prizes.
            </p>
          </div>
          <div>
            <div className={styles.social}>
              <a
                href="https://t.me/justdoluck"
                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                data-wow-offset="50"
                data-wow-delay="0.5s"
              >
                <IconTg />
              </a>
              <a
                href="https://twitter.com/justdoluck"
                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                data-wow-offset="50"
                data-wow-delay="0.6s"
              >
                <IconTw />
              </a>
              <a
                href="https://www.instagram.com/justdoluckworld/"
                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                data-wow-offset="50"
                data-wow-delay="0.7s"
              >
                <IconIn />
              </a>
              <a
                href="https://www.facebook.com/justdoluck"
                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                data-wow-offset="50"
                data-wow-delay="0.8s"
              >
                <IconMeta width={24} height={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        {articles.map((i, id) => (
          <div
            className={clsx(styles["list-item"], "animated wow fadeInUp")}
            data-wow-offset="50"
            data-wow-delay={`0.${3 + id}s`}
            key={i.id}
          >
            <img src={i.image} alt="" className={styles["list-item__img"]} />

            <div className={styles["list-item__bottom"]}>
              <h3 className={styles["list-item__title"]}>{i.title}</h3>
              <p className={styles["list-item__desc"]}>{i.desc}</p>
              <button
                  className={styles.button}
                  onClick={() => window.open(i.link, "_blank")}
              >
                Read
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* <a
        href="/"
        className={clsx(styles.more, "animated wow fadeInUp")}
        data-wow-offset="50"
        data-wow-delay="0.4s"
      >
        More {title.toLocaleLowerCase()}
        <span></span>
        <span></span>
        <span></span>
      </a> */}
    </div>
  );
};
