import React from "react";
import styles from "./style.module.scss";
import clsx from "clsx";
import dice from "../../assets/image/dice.png";
import ok from "../../assets/image/ok.png";
import hair from "../../assets/image/hair.png";
import china from "../../assets/image/china.png";
import chinaTab from "../../assets/image/china-tab.png";
import chinaMob from "../../assets/image/china-mob.png";
import sally from "../../assets/image/Saly-3.png";
import stars from "../../assets/image/stars.png";
import lines from "../../assets/image/way/shk2.png";
import linesTab from "../../assets/image/way/shk-tab2.png";
import linesMob from "../../assets/image/way/shk2-mob.png";
import {
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  icon10,
  icon11,
} from "../../assets";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import useWindowSize from "../../hooks/useWindowSize";
import { News } from "../News";

export const Three = () => {
  const size = useWindowSize();

  return (
    <section className={styles.three}>
      <div className="container">
        <div className={styles.heading}>
          <h2
            className="h2 text-center fadeInUp wow"
            data-wow-offset="50"
            data-wow-delay="0.2s"
          >
            Three whales
          </h2>
          <p
            className="f25 text-center animated rotateInDownLeft wow"
            data-wow-offset="50"
            data-wow-delay="0.3s"
          >
            on which trust in us grows
          </p>
        </div>
        <div className={styles.steps}>
          <div
            className={clsx(
              styles["steps-item"],
              "animated rotateInDownLeft wow"
            )}
            data-wow-duration="2s"
          >
            <span
              className={clsx(
                styles["steps-item__icon"],
                styles["steps-item__icon--dice"]
              )}
            >
              <img src={dice} alt="" />
            </span>
            <h3 className={styles["steps-item__title"]}>Simple</h3>
            <p className={styles["steps-item__desc"]}>
              To win - you need to choose correctly, heads or tails?
            </p>
          </div>
          <div
            className={clsx(
              styles["steps-item"],
              "animated rotateInDownLeft wow"
            )}
            data-wow-offset="50"
            data-wow-delay="0.3s"
            data-wow-duration="2s"
          >
            <span
              className={clsx(
                styles["steps-item__icon"],
                styles["steps-item__icon--ok"]
              )}
            >
              <img src={ok} alt="" />
            </span>
            <h3 className={styles["steps-item__title"]}>Honest</h3>
            <p className={styles["steps-item__desc"]}>
              All players always have equal conditions and chances to become the
              Winner!
            </p>
          </div>
          <div
            className={clsx(
              styles["steps-item"],
              "animated rotateInDownLeft wow"
            )}
            data-wow-offset="50"
            data-wow-delay="0.6s"
            data-wow-duration="2s"
          >
            <span
              className={clsx(
                styles["steps-item__icon"],
                styles["steps-item__icon--hair"]
              )}
            >
              <img src={hair} alt="" />
            </span>
            <h3 className={styles["steps-item__title"]}>Fair</h3>
            <p className={styles["steps-item__desc"]}>The luckiest one wins!</p>
          </div>
        </div>
      </div>
      <div className={styles.emotions}>
        <div className="container">
          <div className={styles["emotions-image"]}>
            <div
              className={clsx(
                styles.shkala1,
                styles.shkpc,
                "animated fadeIn wow"
              )}
              data-wow-offset="50"
              data-wow-delay="0.2s"
            >
              <div className={styles.shkala2}>
                <div className={styles["emo-dot2"]} data-wow-delay="0.2s"></div>
                <img className="image-edges" src={lines} />
              </div>
              <span className={styles.shktitsm1}></span>
            </div>
            <section className={clsx(styles.igrokvigre, styles.shktab)}>
              <div
                className={clsx(styles.shkalatab1, "animated fadeIn wow")}
                data-wow-offset="50"
                data-wow-delay="0.2s"
              >
                <div className={styles.shkalatab2}>
                  <div className={styles["emo-dot3"]}></div>
                  <img className={styles["image-edges"]} src={linesTab} />
                </div>
                <span className={styles.shktitsm2}></span>
              </div>
            </section>

            <section className={styles.shkmob}>
              <div
                className={styles.shkalamob1}
                data-wow-offset="50"
                data-wow-delay="0.2s"
              >
                <div
                  className={clsx(styles.shkalamob2, "animated fadeIn wow")}
                  data-wow-offset="50"
                  data-wow-delay="0.2s"
                >
                  <div
                    className={styles["emo-dot4"]}
                    // styleName="left:77%;top:85.2%;"
                    data-wow-delay="0.2s"
                  ></div>
                  <img className={styles["image-edges"]} src={linesMob} />
                </div>
                <span className={styles.shktitsm3}></span>
              </div>
            </section>

            <h3 className={styles["emotions__title"]}>
              Scale of <span>emotions</span> in raffles:
            </h3>
            <span className={styles.emotions__tip}>
              <b>Tip</b> - let the losses go easily, as it is inevitable :)
            </span>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className={styles.heading}>
            <h2
              className="h2 text-center animated fadeInUp wow"
              data-wow-offset="50"
              data-wow-delay="0.2s"
            >
              <span>What value</span> do we seek to give to each and to society
              as a whole?
            </h2>
          </div>
          <div className={styles.rounds}>
            <div
              className={clsx(
                styles["rounds-round"],
                styles["rounds-round--white"],
                "animated zoomIn wow"
              )}
              data-wow-offset="50"
              data-wow-delay="0.6s"
            >
              <img src={stars} alt="" className={styles.stars} />
              <h3 className={styles["rounds-round__title"]}>Mission</h3>
              <p className={styles["rounds-round__desc"]}>
                – improvement of the model of distribution of valuables ​​on the
                principles of absolute justice for people
              </p>
            </div>
            <div
              className={clsx(
                styles["rounds-round"],
                styles["rounds-round--blue"],
                "animated zoomIn wow"
              )}
              data-wow-offset="50"
              data-wow-delay="0.4s"
            >
              <h3 className={styles["rounds-round__title"]}>Values</h3>
              <p className={styles["rounds-round__desc"]}>
                – simple, understandable, honest, fair!
              </p>
            </div>
            <div
              className={clsx(
                styles["rounds-round"],
                styles["rounds-round--orange"],
                "animated zoomIn wow"
              )}
              data-wow-offset="50"
              data-wow-delay="0.7s"
            >
              <h3 className={styles["rounds-round__title"]}>Goal</h3>
              <p className={styles["rounds-round__desc"]}>
                – to create a new universal ecosystem of relationships in
                society
              </p>
            </div>
          </div>
          <button className={styles.button} onClick={()=>{window.open("https://justdoluck.com", "_target")}}>
            Play and pump your intuition
          </button>
        </div>

        <div id="news">
          <News />
        </div>
        <div className="container">
          <div className={styles.saly}>
            <div
              className={clsx(styles["saly-left"], "fadeInLeft animated wow")}
              data-wow-offset="50"
              data-wow-delay="0.2s"
            >
              <h2 className={clsx(styles["saly-left__title"])}>
                Each of us spends a lot of energy and time looking for{" "}
                <span>new opportunities</span>
              </h2>
              <p className={clsx(styles["saly-left__subtitle"])}>
                Now, using the inalienable right of freedom of choice,{" "}
                <span>it takes a few seconds.</span>
              </p>
            </div>
            <div
              className={clsx(styles["saly-right"], "fadeInRight animated wow")}
              data-wow-offset="50"
              data-wow-delay="1s"
            >
              <img src={sally} alt="" />
            </div>
          </div>
        </div>
        <div
          className={clsx(styles.slider, "animated fadeIn wow animated")}
          data-wow-offset="50"
          data-wow-delay="0.3s"
        >
          <Swiper
            slidesPerView={size > 1200 ? 4 : size < 610 ? "auto" : 4}
            spaceBetween={30}
            className="twoSlider"
            centeredSlides
            loop
            modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--blue"])}>
                <h4 className={styles.card__title}>
                  Human culture was born in a game
                </h4>
                <span className={styles.card__icon}>
                  <img src={icon1} alt="" />
                </span>
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--white"])}>
                <h4 className={styles.card__title}>
                  Play is part of human nature
                </h4>
                <span className={styles.card__icon}>
                  <img src={icon2} alt="" />
                </span>
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--blue"])}>
                <h4 className={styles.card__title}>
                  Human culture was born in a game
                </h4>
                <span className={styles.card__icon}>
                  <img src={icon3} alt="" />
                </span>
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--orange"])}>
                <h4 className={styles.card__title}>People always play</h4>
                <span className={styles.card__icon}>
                  <img src={icon4} alt="" />
                </span>
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--blue"])}>
                <h4 className={styles.card__title}>
                  Games create the phenomenon of potentiality
                </h4>
                <span
                  className={clsx(
                    styles.card__icon,
                    styles["card__icon--middle"]
                  )}
                >
                  <img src={icon5} alt="" />
                </span>
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--white"])}>
                <h4 className={styles.card__title}>
                  Play is the highest form of exploration
                </h4>
                <span
                  className={clsx(
                    styles.card__icon,
                    styles["card__icon--middle"]
                  )}
                >
                  <img src={icon6} alt="" />
                </span>
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--blue"])}>
                <h4 className={styles.card__title}>
                  The world is based on games. Everyone plays. And here live
                  talent is important - a gift from birth.
                </h4>
                <span
                  className={clsx(
                    styles.card__icon,
                    styles["card__icon--middle"]
                  )}
                >
                  <img src={icon7} alt="" />
                </span>
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--orange"])}>
                <h4 className={styles.card__title}>
                  The game must be fair, otherwise it makes no sense.
                </h4>
                <span
                  className={clsx(
                    styles.card__icon,
                    styles["card__icon--target"]
                  )}
                >
                  <img src={icon8} alt="" />
                </span>
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--blue"])}>
                <h4 className={styles.card__title}>
                  To win, you must first start playing
                </h4>
                <span
                  className={clsx(
                    styles.card__icon,
                    styles["card__icon--gamepad"]
                  )}
                >
                  <img src={icon9} alt="" />
                </span>
                <span className={styles.card__author}>Albert Einstein</span>
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--white"])}>
                <h4 className={styles.card__title}>
                  What is our life? The game
                </h4>
                <span className={styles.card__icon}>
                  <img src={icon10} alt="" />
                </span>
                <span className={styles.card__author}>Alexander Pushkin</span>
              </div>
            </SwiperSlide>

            <SwiperSlide className={styles.slider__slide}>
              <div className={clsx(styles.card, styles["card--blue"])}>
                <h4 className={styles.card__title}>
                  Our whole life is a game, and the people in it are actors.
                </h4>
                <span
                  className={clsx(styles.card__icon, styles["card__icon--big"])}
                >
                  <img src={icon11} alt="" />
                </span>
                <span className={styles.card__author}>William Shakespeare</span>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};
