import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import style from "./style.module.scss"
class YoutubeModal extends React.Component {
    constructor () {
        super();
        this.state = {
            showModal: false
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false });
    }

    render () {
        return (
            <div>
                <button onClick={this.handleOpenModal}>Trigger Modal</button>
                <Modal
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                    style={{
                        height: "50%"
                    }}

                >
                    <div className={style.video_iframe}>
                        <iframe
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/gJ_BB38zMBA"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                    <button onClick={this.handleCloseModal} className={style.closeModal}>Close Modal</button>
                </Modal>
            </div>
        );
    }
}

const YoutubeModal1 = (props)=>{

    useEffect(()=>{
       try{
          setTimeout(()=>{
              const portal = document.getElementsByClassName("ReactModalPortal")[0];
              portal.getElementsByClassName("ReactModal__Content ")[0].style.height = "80%"
              portal.getElementsByClassName("ReactModal__Content ")[0].style.marginTop = "5%"
              console.log(portal)

          }, 1000)
       }
       catch (e){}
    })
    return <div>
        <Modal
            isOpen={props.isOpen}
        >
            <div className={style.video_iframe}>
                <iframe
                    width="100%"
                    height="100%"
                    src={props.link}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
            <button onClick={()=>{props.setOpen(false)}} className={style.closeModal}>X</button>
        </Modal>
    </div>
}
export default YoutubeModal1