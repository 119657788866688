import React from "react";
import apple from "../../assets/image/apple.png";
import google from "../../assets/image/google.png";
import styles from "./style.module.scss";
import {IconCopyring, IconIn, IconLogo, IconMeta, IconTg, IconTw,} from "../../assets";
import {Link} from "react-scroll";
import clsx from "clsx";

export const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className={styles["footer-inner"]}>
                    <div
                        className={clsx(styles.left, "animated wow fadeInLeft")}
                        data-wow-offset="50"
                    >
                        <h3 className={styles.left__head}>
                            Sharing new
                            <span>events and emotions</span>
                        </h3>
                        <p className="f25">
                            Subscribe to us on social networks to be the first to know about
                            new raffles and the best prizes
                        </p>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.social}>
                            <a
                                href="https://t.me/justdoluck"
                                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                                data-wow-offset="50"
                                data-wow-delay="0.5s"
                            >
                                <IconTg/>
                            </a>
                            <a
                                href="https://twitter.com/justdoluck"
                                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                                data-wow-offset="50"
                                data-wow-delay="0.6s"
                            >
                                <IconTw/>
                            </a>
                            <a
                                href="https://www.instagram.com/justdoluckworld/"
                                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                                data-wow-offset="50"
                                data-wow-delay="0.7s"
                            >
                                <IconIn/>
                            </a>
                            <a
                                href="https://www.facebook.com/justdoluck"
                                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                                data-wow-offset="50"
                                data-wow-delay="0.8s"
                            >
                                <IconMeta width={24} height={24}/>
                            </a>
                        </div>
                        <div className={styles.bottom}>
                            <p className={styles.bottom__label}>Download apps</p>
                            <div className={styles["bottom-inner"]}>
                                <a href="/" className={styles["bottom__link"]}>
                                    <img src={google} alt="" height={80} width={200}/>
                                </a>
                                <a href="/" className={styles["bottom__link"]} style={{marginTop: "14px"}}>
                                    <img src={apple} alt="" height={55} width={175}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={styles.nav}>
                    <div className={clsx(styles.logo, "animated bounceInLeft wow")}>
                        <IconLogo/>
                    </div>
                    <ul className={styles["nav-list"]}>
                        <li className={styles["nav-list__item"]}>
                            <Link
                                to="banner"
                                activeClass={styles.active}
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={500}
                                className={styles["nav-list__link"]}
                            >
                                About the project
                            </Link>
                        </li>
                        <li className={styles["nav-list__item"]}>
                            <Link
                                to="news"
                                activeClass={styles.active}
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={500}
                                className={styles["nav-list__link"]}
                            >
                                News
                            </Link>
                        </li>
                        <li className={styles["nav-list__item"]}>
                            <Link
                                to="article"
                                activeClass={styles.active}
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={500}
                                className={styles["nav-list__link"]}
                            >
                                Articles
                            </Link>
                        </li>
                        <li className={styles["nav-list__item"]}>
                            <Link
                                to="faq"
                                activeClass={styles.active}
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={500}
                                className={styles["nav-list__link"]}
                            >
                                FAQ
                            </Link>
                        </li>
                        <li className={styles["nav-list__item"]}>
                            <a
                                href="https://justdoluck.medium.com"
                                className={styles["nav-list__link"]}
                            >
                                <span></span> Blog
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <div className={styles.copyring}>
                    <IconCopyring/> {new Date().getFullYear()} - Just do luck Inc.
                </div>
                <div className={styles.law_address}>
                    3876 Quarter Mile Drive, CA 92130, USA
                </div>
            </div>
        </footer>
    );
};
