import React, { useState, useRef } from "react";
import clsx from "clsx";
import styles from "./style.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper";
import "swiper/css";

import "swiper/css/navigation";
import { IconRight } from "../../assets";
import useWindowSize from "../../hooks/useWindowSize";

const arrTitles = [
  "Unbreakable Foundation",
  "We appreciate your values",
  "Long-term unwavering trust",
  "Additional Opportunities ",
];

export const Principles = () => {
  const [clickedIndex, setClickedIndex] = useState(0);
  const navigationPrevRef = React.useRef<HTMLButtonElement>(null);
  const navigationNextRef = React.useRef<HTMLButtonElement>(null);
  const size = useWindowSize();
  const wArrow = size > 1300;

  return (
    <div className={styles.principles}>
      <div className="container">
        <div className={styles.heading}>
          <h2
            className="h2 text-center animated fadeInUp wow"
            data-wow-offset="50"
            data-wow-delay="0.5s"
          >
            Principles of absolute justice Just do luck
          </h2>
        </div>
      </div>

      <div className={styles.slider}>
        <div className={styles.arrows}>
          <button
            className={clsx(styles.arrow, "next", styles["arrow--left"])}
            ref={navigationPrevRef}
          >
            {size > 1300 && clickedIndex === 0 ? null : <IconRight />}
            <>
              <span className={styles.arrow__text}>
                {clickedIndex > 0
                  ? arrTitles[
                      clickedIndex > 0 && clickedIndex < arrTitles.length
                        ? clickedIndex - 1
                        : clickedIndex
                    ]
                  : null}
              </span>
            </>
          </button>
          <button
            className={clsx(styles.arrow, " prevs")}
            ref={navigationNextRef}
          >
            {size > 1300 && clickedIndex === arrTitles.length - 1 ? null : (
              <IconRight />
            )}
            <>
              <span className={styles.arrow__text}>
                {clickedIndex < arrTitles.length - 1
                  ? arrTitles[
                      clickedIndex >= 0 && clickedIndex < arrTitles.length
                        ? clickedIndex + 1
                        : clickedIndex
                    ]
                  : null}
              </span>
            </>
          </button>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          centeredSlides={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          modules={[Navigation, A11y]}
          className="mySwiper"
          onSlideChange={(swiper) => {
            setClickedIndex(swiper.activeIndex);
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
        >
          <SwiperSlide>
            <div className="container">
              <div className={styles.slide}>
                <h3 className={styles.slide__title}>Unbreakable Foundation</h3>
                <div className={styles.stack}>
                  <div className={styles["stack-list"]}>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>Simple</div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        Simple choice: heads or tails?
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Understandable
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        Choice of one of two equally possible and equally
                        probable options
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Honestly
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        Always equal probability - 50% to 50%
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>Fair</div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        100% equal conditions for everyone all the time
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container">
              <div className={styles.slide}>
                <h3 className={styles.slide__title}>
                  We appreciate your values
                </h3>
                <div className={styles.stack}>
                  <div className={styles["stack-list"]}>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Micro-attention
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        Short selective concentration of consciousness
                        (short-term neural activity) - "algorithmic" value
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Micro-choice
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        Hundreds of times we make it, expressing our free will,
                        thereby determining our lives. It's language of freedom.
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Micro-time
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        The world is accelerating, time is shrinking, seconds
                        are valuable today!
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Micro-action
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        Does not cause fatigue, fatigue, satiety
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container">
              <div className={styles.slide}>
                <h3 className={styles.slide__title}>
                  Long-term unwavering trust
                </h3>
                <div className={styles.stack}>
                  <div className={styles["stack-list"]}>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Absolutely transparent
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        All participants know and see their choice, they realize
                        that if you guess right, you move on; if you don't,
                        you're out. Manipulation and misunderstanding - is
                        excluded.
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Verifiable
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        Everyone can check "why did it come up heads or tails?"
                        By going to the JDL algorithm website. Any value can be
                        double-checked and it is possible to do the calculations
                        yourself.
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Independent from anyone
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        No one can influence the operation of the JDL algorithm
                        and the mechanism for determining the winner, except for
                        the choice of the participant
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>Public</div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        Everything is open. Everyone sees everything.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="container">
              <div className={styles.slide}>
                <h3 className={styles.slide__title}>
                  Additional Opportunities
                </h3>
                <div className={styles.stack}>
                  <div className={styles["stack-list"]}>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Impossible is possible
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        Yesterday there was no JDL, and today, relying on your
                        right to choose, you can win any prize that will be
                        played
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Inaccessible is available
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        Yesterday the dream was far away and inaccessible, today
                        a few mouse clicks or pokes at the monitor are enough to
                        make the desired reality available.
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Complex is simple
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        The world is constantly becoming more complicated, but
                        we simplify, making the complex simple.
                      </div>
                    </div>
                    <div className={styles["stack-item"]}>
                      <div className={clsx(styles["stack-button"])}>
                        Expensive is free
                      </div>
                      <div
                        className={clsx(styles["stack-text"], styles.width530)}
                      >
                        The world has turned upside down. Luck and intuition
                        determine what is free and what is not available.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
