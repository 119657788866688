import React, {useEffect, useState} from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import {NewsType} from "../../mock";
import Modal from "../modal";
import {IconIn, IconMeta, IconTg, IconTw} from "../../assets";
import EnvController from "../../utils/EnvController";

export const News = () => {
  const [open, setOpen] = useState("");

  const [news, setNews] = useState<NewsType[]>([]);
  const [modal, setModal] = useState("");
  const [loader, setLoader] = useState(true);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoader(true);
        let responce = await fetch(
            `${EnvController.getEnvController().wayToApi}news/public-scope/news?page=1&count=3`, {
              headers: {
                "Api-Key": "justdoluck_api"
              }
            }
        );
        let json = await responce.json();
        if (json.success) {
          setNews(json.data.news);
        }
        setLoader(false);
      } catch (e) {
        console.log(e);
        setLoader(false);
      }
    })();
  }, []);

  const modalContent = async (id: number) => {
    try {
      setLoad(true);
      let responce = await fetch(`${EnvController.getEnvController().wayToApi}news/public-scope/news/${id}`, {
        headers: {
          "Api-Key": "justdoluck_api"
        }
      });
      let json = await responce.json();
      if (json.success) {
        const data: NewsType = json.data.news;
        const content = `
        <br />
          <br />
          <div></div>
          <h2>
            ${data.title}
          </h2>
          <p>
           ${data.shortDescription}
          </p>
          <img src="${data.photo}" />
          ${data.description ? data.description.split('&gt;').join('>').split('&lt;').join('<') : ""}
        `;
        setLoad(false);
        setOpen(content);
      }
    } catch (e) {
      console.log(e);
      setLoad(false);
    }
  };

  return (
    <div className={styles.article}>
      <Modal open={!!open} onClose={() => setOpen("")}>
        <div className={styles.modal}>
          <div dangerouslySetInnerHTML={{ __html: open }} />
        </div>
      </Modal>
      <div className="container">
        <div
          className={clsx(styles.heading, "animated wow fadeInLeft")}
          data-wow-offset="50"
          data-wow-delay="0.2s"
        >
          <div>
            <h2 className="h2">
              News
              <span>Just do luck</span>
            </h2>
            <p className="f25">
              Follow us on social networks to be the first to know about new
              raffles and the best prizes.
            </p>
          </div>
          <div>
            <div className={styles.social}>
              <a
                href="https://t.me/justdoluck"
                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                data-wow-offset="50"
                data-wow-delay="0.5s"
              >
                <IconTg />
              </a>
              <a
                href="https://twitter.com/justdoluck"
                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                data-wow-offset="50"
                data-wow-delay="0.6s"
              >
                <IconTw />
              </a>
              <a
                href="https://www.instagram.com/justdoluckworld/"
                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                data-wow-offset="50"
                data-wow-delay="0.7s"
              >
                <IconIn />
              </a>
              <a
                href="https://www.facebook.com/justdoluck"
                className={clsx(styles["social-item"], "animated wow fadeInUp")}
                data-wow-offset="50"
                data-wow-delay="0.8s"
              >
                <IconMeta width={24} height={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        {news.map((i, id) => (
          <div
            className={clsx(styles["list-item"], "animated wow fadeInUp")}
            data-wow-offset="50"
            data-wow-delay={`0.${3 + id}s`}
            key={i.id}
          >
            <img src={i.photo} alt="" className={styles["list-item__img"]} />

            <div className={styles["list-item__bottom"]}>
              <h3 className={styles["list-item__title"]}>{i.title}</h3>
              <p className={styles["list-item__desc"]}>{i.shortDescription}</p>
              <button
                disabled={load}
                className={styles.button}
                onClick={() => modalContent(i.id)}
              >
                Read
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* <a
        href="/"
        className={clsx(styles.more, "animated wow fadeInUp")}
        data-wow-offset="50"
        data-wow-delay="0.4s"
      >
        More {title.toLocaleLowerCase()}
        <span></span>
        <span></span>
        <span></span>
      </a> */}
    </div>
  );
};
